<template>
  <SweetModal ref="modal" icon="warning" hide-close-button overlay-theme="dark">
    <div class="mt-3" v-if="invalidTags.length > 0">
      <p class="lead">As seguintes tags são invalidas</p>
      <span v-for="(tag, idx) in invalidTags" :key="idx">
        {{ getTag(tag) }}
      </span>
      <p class="text-muted mt-3">
        Favor remover do corpo do email as tags mostradas acima
      </p>
    </div>
    <hr v-if="invalidTags.length > 0 && requiredTags.length > 0" />
    <div class="mt-3" v-if="requiredTags.length > 0">
      <p class="lead">As seguintes tags são obrigatórias</p>
      <span v-for="(tag, idx) in requiredTags" :key="idx"
        >{{ getTag(tag) }}
      </span>
      <p class="text-muted mt-3">
        Favor incluir no corpo do email as tags conforme mostradas acima
      </p>
    </div>
    <template #button>
      <b-button @click="close" variant="warning">Ok</b-button>
    </template>
  </SweetModal>
</template>

<script>
export default {
  data() {
    return {
      requiredTags: [],
      invalidTags: [],
    };
  },
  methods: {
    open(required, invalid) {
      this.requiredTags = [];
      this.invalidTags = [];
      this.requiredTags = required;
      this.invalidTags = invalid;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style>
</style>